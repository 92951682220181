import axios, { AxiosRequestConfig } from 'axios';

import {
  axiosBaseConfigOptions,
  axiosInterceptorRequest,
  axiosInterceptorResponseError
} from './axiosBaseConfig';

const defaultOptions: AxiosRequestConfig = {
  ...axiosBaseConfigOptions,
  baseURL: process.env.AUTH_SERVICE_URL
};
export const axiosAuthInstance = axios.create(defaultOptions);

axiosAuthInstance.interceptors.request.use(axiosInterceptorRequest);

// FIXME: infinite loading when user open this web app in more than one browsers
axiosAuthInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    axiosInterceptorResponseError({
      error,
      instance: axiosAuthInstance
    })
);
